@import "primeicons/primeicons.css";

*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
}

body{
  width: 100vw;
  overflow-x: hidden !important;
  max-width: 100vw !important;
}

html{
  width: 100vw;
  min-width: 320px;
  scroll-behavior: smooth;
}
